import request from '@/utils/request'


export class ContactTaskApi {
  // 任务列表
  static index (params) {
    return request({
      url: '/followContactTask/index',
      method: 'get',
      params: params
    })
  }

  // 任务执行成员列表
  static employeeIndex (params) {
    return request({
      url: '/followContactTask/employeeIndex',
      method: 'get',
      params: params
    })
  }

  // 跟进客户列表
  static contactIndex (params) {
    return request({
      url: '/followContactTask/contactIndex',
      method: 'get',
      params: params
    })
  }

  // 跟进详情
  static followShow (params) {
    return request({
      url: '/followContactTask/followShow',
      method: 'get',
      params: params
    })
  }

  // 跟进记录
  static follow (params) {
    return request({
      url: '/followContactTask/follow',
      method: 'put',
      data: params
    })
  }
  
}