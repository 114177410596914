<template>
  <div class="index">
    <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      fixed
      placeholder 
      @click-left="onClickLeft"
    />
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <van-cell-group inset v-for="(item, index) in list" :key="index" class="list-item" @click="checkDetail(item.employee_id, item.employee
.name)">
          <van-cell is-link style="align-items: center;">
            <template #title>
              <span class="custom-title list-item-title">
                <van-image
                  width="44"
                  height="44"
                  fit="cover"
                  round
                  :src="item.employee.avatar"
                />
                {{ item.employee.name }}
              </span>
            </template>
          </van-cell>
          
          <van-cell>
            <template #title>
              <span class="custom-title list-item-label" style="margin-right: 10px">负责人数/已跟进人数</span>
            </template>
            <template>
              <span class="list-item-value">
                <van-icon name="friends-o" /> {{ item.total_contact }} / <van-tag plain type="primary"> {{ item.contacted }} </van-tag>
              </span>
            </template>
          </van-cell>
          <van-cell>
            <template #title>
              <span class="custom-title list-item-label" style="margin-right: 10px">最后跟进时间</span>
            </template>
            <template>
              <span class="list-item-value">{{ item.updated_at }}</span>
            </template>
          </van-cell>
        </van-cell-group>
        <!-- <div class="list-item" v-for="(item, index) in list" :key="index">
          
        </div> -->
        <div v-if="finished && list.length" slot="finished">无更多数据</div>
      </van-list>
      <van-empty
        v-if="finished && !list.length"
        class="custom-image"
        :image="require('../../assets/images/search-no-data.svg')"
        description="暂无数据"
      />
    </div>
  </div>
</template>
<script>
import { getCookie } from '@/plugins/utils'
import { ContactTaskApi } from '@/api/contactTask'
export default {
  data() {
    return {
      taskId: '',
      title: '',
      serchValue: '',
      loading: false,
      finished: false,
      list: [],
      page: 1,
      perPage: 15
    }
  },
  created () {
    this.taskId = this.$route.query.id
    this.title = this.$route.query.title
  },
  methods: {
    // 返回上一页
    onClickLeft() {
      this.$router.back()
    },
    onLoad () {
      this.loading = true
      ContactTaskApi.employeeIndex({
        contact_task_id: this.taskId,
        page: this.page,
        page_size: this.perPage
      }).then(res => {
        this.loading = false
        if(res.data.lists.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.list = this.list.concat(res.data.lists.data || [])
      })
    },
    checkDetail (employee_id, employee_name) {
      this.$router.push('/contactTask/contact?contact_task_id=' + this.taskId + '&employee_id=' + employee_id + '&employee_name=' + employee_name)
    },
    onSearch () {},
    clearSerch () {},
    // 路由跳转
    routerPush(e) {
      this.$router.push(e)
    },
  }
}
</script>
<style scoped lang="less">
.index {
  font-size: 14px;
  height: 100%;
  background: #f4f5f7;

  /deep/ .van-nav-bar__content {
    background: #2b63cd;
    color: #fff;
  }

  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }

  /deep/ .van-nav-bar__text {
    color: #fff;
  }

  /deep/ .van-nav-bar__title {
    color: #fff;
  }

  .content {

    /deep/.van-cell-group {
      margin: 0;
    }
    // background: #f4f5f7;
    padding: 16px 12px;

    .list-item {
      margin-bottom: 16px;
      background: #fff;
      box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);

      &-title {
        display: flex;
        align-items: center;

        .van-image {
          margin-right: 5px;
        }
      }

      &-label {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
      }

      &-value {
        
      }
    }

  }
}
</style>
