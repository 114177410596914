<template>
  <div class="index">
    <van-nav-bar
      :title="employee_name + '跟进客户'"
      left-text="返回"
      left-arrow
      fixed
      placeholder 
      @click-left="onClickLeft"
    />
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <van-cell-group inset v-for="(item, index) in list" :key="index" class="list-item" @click="checkDetail(item)">
          <van-cell is-link style="align-items: center;">
            <template #title>
              <span class="custom-title list-item-title">
                <van-image
                  width="44"
                  height="44"
                  fit="cover"
                  round
                  :src="item.contact.avatar"
                />
                <div>
                  {{ item.contact.name }}
                  <div>
                    <van-tag v-if="item.status == 1" plain type="primary">已完成</van-tag>
                    <van-tag v-else plain type="warning">未完成</van-tag>
                  </div>
                </div>
                
              </span>
            </template>
            <template v-if="item.status == 1">
              详情
            </template>
            <template v-if="item.status == 0 && userPermission >= 2">
              跟进
            </template>
          </van-cell>
        </van-cell-group>
        <div v-if="finished && list.length" slot="finished">无更多数据</div>
      </van-list>
      <van-empty
        v-if="finished && !list.length"
        class="custom-image"
        :image="require('../../assets/images/search-no-data.svg')"
        description="暂无数据"
      />
    </div>
    <!--  -->
    <van-dialog v-model="show" confirmButtonColor="#1989fa">
      <van-cell-group class="list-item" style="box-shadow: none">
        <van-cell style="align-items: center;" v-if="detailData.contact">
          <template #title>
            <span class="custom-title list-item-title">
              <van-image
                width="44"
                height="44"
                fit="cover"
                round
                :src="detailData.contact.avatar"
              />
              <div>
                {{ detailData.contact.name }}
                <div>
                  <van-tag v-if="detailData.status == 1" plain type="primary">已完成</van-tag>
                  <van-tag v-else plain type="warning">未完成</van-tag>
                </div>
              </div>
            </span>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <span class="custom-title list-item-title">
              跟进时间
            </span>
          </template>
          <template>
            {{ detailData.follow_time }}
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <span class="custom-title list-item-title">
              跟进内容
            </span>
          </template>
          <template>
            {{ detailData.follow_log }}
          </template>
        </van-cell>
      </van-cell-group>
      <div style="margin-bottom: 20px; ">
        <div style="font-size: 12px; padding: 0 15px; color: #999">点击查看大图</div>
        <van-grid :border="false" :column-num="3" style="padding: 0 6px;">
          <van-grid-item v-for="(item, index) in detailData.images" :key="index">
            <van-image @click="checkImage(index)" width="100%" height="100" fit="cover" :src="item + '?x-oss-process=image/resize,w_200/quality,q_50'" />
          </van-grid-item>
        </van-grid>
      </div>
    </van-dialog>
    <!-- 跟进 -->
    <van-dialog v-model="showEdit" confirmButtonColor="#1989fa" showCancelButton @confirm="follow" @cancel="cancelFollow">
      <!-- <van-cell-group class="list-item" style="box-shadow: none">
        <van-cell>
          <template #title>
            <span class="custom-title list-item-title">
              跟进内容
            </span>
          </template>
          <template>
            {{ detailData.follow_log }}
          </template>
        </van-cell>
      </van-cell-group> -->
      <van-field
        style="margin-top: 20px"
        v-model="editData.follow_log"
        rows="2"
        autosize
        label="留言"
        type="textarea"
        maxlength="50"
        placeholder="请输入留言"
        show-word-limit
      />
      
      <div style="margin-bottom: 20px; padding: 20px">
        <div style="font-size: 12px; margin-bottom: 10px; color: #999">上传图片</div>
        <van-uploader :after-read="afterRead" multiple v-model="fileList" :max-count="5" :max-size="2048 * 1024" @oversize="onOversize"/>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { getCookie } from '@/plugins/utils'
import { ContactTaskApi } from '@/api/contactTask'
import {upload} from "@/api/contactWelcome";
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Dialog, ImagePreview, Uploader, Toast } from 'vant'
Vue.use(Uploader)
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  computed:{
		...mapGetters([
			'userPermission'
		])
	},
  data() {
    return {
      taskId: '',
      employee_id: '',
      employee_name: '',
      serchValue: '',
      loading: false,
      finished: false,
      list: [],
      page: 1,
      perPage: 15,
      show: false,
      detailData: {},

      showEdit: false,
      editData: {},
      fileList: []
    }
  },
  created () {
    this.taskId = this.$route.query.contact_task_id
    this.employee_id = this.$route.query.employee_id || ''
    this.employee_name = this.$route.query.employee_name || ''
  },
  methods: {
    cancelFollow () {
      this.showEdit = false
      this.editData = {}
    },
    follow () {
      let images = []
      for (var i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].status == 'success') {
          images.push(this.fileList[i].url)
        }
      }
      this.editData.images = images
      this.editData.status = 1
      ContactTaskApi.follow(this.editData).then(res => {
        this.showEdit = false
        this.editData = {}
        this.page = 1
        this.finished = false
        this.list = []
        this.onLoad()
      })
    },
    afterRead (file) {
      file.status = 'uploading';
      file.message = '上传中...';
      this.uploadShow = 'showUpload'
      var formData = new FormData();
      formData.append('file', file.file);
      upload(formData).then(res => {
        if (res.code == 200) {
          file.status = 'success';
          file.message = '上传成功';
          file.url = res.data.fullPath
        } else {
          file.status = 'failed';
          file.message = '上传失败';
        }
      })
    },
    onOversize(file) {
      console.log(file);
      Toast('文件大小不能超过 2MB');
    },
    checkImage (index) {
      ImagePreview({
        images: this.detailData.images,
        startPosition: index,
      })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back()
    },
    onLoad () {
      this.loading = true
      ContactTaskApi.contactIndex({
        contact_task_id: this.taskId,
        employee_id: this.employee_id,
        page: this.page,
        page_size: this.perPage
      }).then(res => {
        this.loading = false
        if(res.data.lists.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.list = this.list.concat(res.data.lists.data || [])
      })
    },
    checkDetail (item) {
      if (this.userPermission < 2) {
        ContactTaskApi.followShow({id: item.id}).then(res => {
          this.detailData = res.data
          this.show = true
        })
      } else {
        if (item.status == 1) {
          ContactTaskApi.followShow({id: item.id}).then(res => {
            this.detailData = res.data
            this.show = true
          })
        } else {
          this.editData.id = item.id
          this.showEdit = true
        }
      }
      
    },
    onSearch () {},
    clearSerch () {},
    // 路由跳转
    routerPush(e) {
      this.$router.push(e)
    },
  }
}
</script>
<style scoped lang="less">
.index {
  font-size: 14px;
  height: 100%;
  background: #f4f5f7;

  /deep/ .van-nav-bar__content {
    background: #2b63cd;
    color: #fff;
  }

  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }

  /deep/ .van-nav-bar__text {
    color: #fff;
  }

  /deep/ .van-nav-bar__title {
    color: #fff;
  }

  .content {

    /deep/.van-cell-group {
      margin: 0;
    }
    // background: #f4f5f7;
    padding: 16px 12px;

    .list-item {
      margin-bottom: 16px;
    }

  }

  .list-item {
    margin-bottom: 16px;
    background: #fff;
    box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);

    &-title {
      display: flex;
      align-items: center;

      .van-image {
        margin-right: 8px;
      }
    }

    &-label {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #999;
    }

    &-value {
      
    }
  }
}
</style>
